import { backEndApi } from '@root/libs';

const api = '/api/v1';
const inventoryPath = '/inventory';

const inventoryAPIs = {
  getFileCsvDownload: (body) =>
    backEndApi.post(`${api}${inventoryPath}/download`, body),
  getProductAccessGroups: (body) =>
    backEndApi.post(`${api}${inventoryPath}/access-groups`, body),
  renewUserInventory: (body) =>
    backEndApi.post(`${api}${inventoryPath}/renew-data`, body),
  getAutoRevokeHistory: (ruleId) =>
    backEndApi.get(
      `${api}${inventoryPath}/auto-revoke/rule/${ruleId}/histories`,
    ),
  getAutoRevokeRules: () =>
    backEndApi.get(`${api}${inventoryPath}/auto-revoke/rules`),
  createAutoRevokeRule: (body) =>
    backEndApi.post(`${api}${inventoryPath}/auto-revoke/rule`, body),
  updateAutoRevokeRule: (ruleId, body) =>
    backEndApi.put(`${api}${inventoryPath}/auto-revoke/rule/${ruleId}`, body),
  getAutoRevokeHistoryPresignedURL: ({ ruleId, executedAt }) =>
    backEndApi.get(
      `${api}${inventoryPath}/auto-revoke/rule/${ruleId}/histories/download?executedAt=${executedAt}`,
    ),
  deleteAutoRevokeRule: (ruleId) =>
    backEndApi.delete(`${api}${inventoryPath}/auto-revoke/rule/${ruleId}`),
};

export default inventoryAPIs;
