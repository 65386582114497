import { makeStyles, styled } from '@material-ui/styles';
import Select, { AsyncSelect, components } from '@atlaskit/select';
import SearchIcon from '@atlaskit/icon/glyph/search';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useStyles = makeStyles(() => ({
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0',
  },
  searchMulti: {
    width: '100%',
  },
  searchSites: {
    width: '100%',
    minWidth: 260,
    fontSize: '14px',
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
  searchTitle: {
    marginRight: '20px',
    minWidth: 'fit-content',
  },
}));

const Box = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  justifyContent: 'center',
});

const EmptyOption = ({ text = i18next.t('common.noDataAvailable') }) => (
  <Box>{text}</Box>
);
const EmptyOptionSearch = ({ text = i18next.t('common.noDataAvailable') }) => (
  <Box>
    <SearchIcon size='small' />
    {text}
  </Box>
);

const AsyncSelectWrapper = ({ children, loadOptions, ...props }) => (
  <AsyncSelect loadOptions={loadOptions} {...props}>
    {children}
  </AsyncSelect>
);

const SelectCustom = ({ value, name, options = [], onChange = () => {} }) => {
  const classes = useStyles();

  const CustomIssueOption = ({ data, ...props }) => {
    const organizationName = data.label;
    return (
      <components.Option {...props} style={{ display: 'none' }}>
        <div className={classes.options}>{organizationName}</div>
      </components.Option>
    );
  };

  return (
    <Select
      name={name}
      blurInputOnSelect
      value={value}
      components={{
        Option: CustomIssueOption,
      }}
      onChange={onChange}
      options={options}
      openMenuOnClick
      closeMenuOnSelect
    />
  );
};

const SelectOrganization = ({
  value,
  name = 'organizations',
  options = [],
  onChange,
  defaultValues,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={`${classes.searchWrapper} ${classes.searchMulti}`}>
      <span className={classes.searchTitle}>
        {t('ipAllowList.organizations')}
      </span>
      <div className={classes.searchSites}>
        <SelectCustom
          name={name}
          value={value}
          defaultValues={defaultValues}
          onChange={onChange}
          options={options}
        />
      </div>
    </div>
  );
};

export {
  AsyncSelectWrapper,
  SelectCustom,
  SelectOrganization,
  EmptyOption,
  EmptyOptionSearch,
};
