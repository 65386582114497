export const timePicker = [
  { label: '00:00 ', value: 0, extra: 'extra' },
  { label: '01:00 ', value: 1 },
  { label: '02:00 ', value: 2 },
  { label: '03:00 ', value: 3 },
  { label: '04:00 ', value: 4 },
  { label: '05:00 ', value: 5 },
  { label: '06:00 ', value: 6 },
  { label: '07:00 ', value: 7 },
  { label: '08:00 ', value: 8 },
  { label: '09:00 ', value: 9 },
  { label: '10:00 ', value: 10 },
  { label: '11:00 ', value: 11 },
  { label: '12:00 ', value: 12 },
  { label: '13:00 ', value: 13 },
  { label: '14:00 ', value: 14 },
  { label: '15:00 ', value: 15 },
  { label: '16:00 ', value: 16 },
  { label: '17:00 ', value: 17 },
  { label: '18:00 ', value: 18 },
  { label: '19:00 ', value: 19 },
  { label: '20:00 ', value: 20 },
  { label: '21:00 ', value: 21 },
  { label: '22:00 ', value: 22 },
  { label: '23:00 ', value: 23 },
];

export const NotificationStatus = [
  { label: 'Enable', value: true, extra: 'extra' },
  { label: 'Disable', value: false },
];
export const changeTimeToServerTime = (time) => {
  const userDate = new Date();
  userDate.setHours(time);
  return userDate.getUTCHours();
};

export const changeTimeToUserTime = (time) => {
  const utcHour = time;

  // Create a Date object with the UTC hour
  const userDate = new Date();
  userDate.setUTCHours(utcHour);

  return userDate.getHours();
};
