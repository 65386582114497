import Button from '@atlaskit/button';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import NotificationIcon from '@atlaskit/icon/glyph/notification';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import PersonIcon from '@atlaskit/icon/glyph/person';
import PreferencesIcon from '@atlaskit/icon/glyph/preferences';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import SectionMessage from '@atlaskit/section-message';
import AppBarMUI from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/styles';
import ModalInfo from '@root/app/ModalInfo';
import logo from '@root/images/d_accel_yoko.png';
import { organizationAPIs } from '@root/services';
import { checkDate } from '@root/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import appActions from '../../redux/app/actions';
import NavBar from '../common/NavBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 200,
    'background-color': '#fff',
    borderBottom: '4px solid orange',
    '& .dx-notice-container > section': {
      padding: '16px 35px',
      alignItems: 'center',
      justifyContent: 'center',
      '& > div:nth-child(2)': {
        flexGrow: 0,
      },
    },
    '& .dx-maintenance-notice': {
      color: '#303952',
      fontSize: 13,
    },
  },
  appBarLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pinIcon: {
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    width: '175px',
    height: '42px',
  },
  homePageBtn: {
    width: '100%',
    display: 'flex',
    padding: '8px 14px',
    fontSize: '18px',
    color: '#42526E',
    cursor: 'pointer',
    alignItems: 'center',
    textDecoration: 'none',
    marginLeft: 80,
    '& span': {
      marginLeft: 5,
    },
  },
  connectCognito: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  navItem: {
    textEmphasis: 'none',
  },
  icon: {
    primaryColor: '#303952',
  },
  settingsItem: {
    position: 'relative',
  },
  keyWarning: {
    position: 'absolute',
    color: '#303952',
    top: 44,
    right: 56,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  arrowUp: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    marginRight: 20,
    borderRight: '6px solid transparent',
    borderBottom: '6px solid #fffae6',
  },
}));

const AppBar = ({
  windowSize,
  handleLogout,
  isAccess,
  setIsRefresh,
  siteAdmin,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState('');
  const { t } = useTranslation();
  const user = useSelector((state) => state.app.currentUser);

  const [needApiKey, setNeedApiKey] = useState(false);
  const isFirstAddOrgApiKey =
    currentLocation.pathname === '/register-organization-api-key';
  const shouldStorageSettingMenuItemDisplayed =
    currentLocation.pathname === '/admin-center/audit-log';

  const fetchOrganizations = async () => {
    const response = await organizationAPIs.getAllOrganizationAPIKeys();
    const orgs = response.data.organizations;
    const condition = (element) =>
      checkDate(element.expirationDate) && element.apikeyError !== 'wrong_key';

    setNeedApiKey(!orgs.some(condition));
  };

  const renewData = useSelector((state) => state?.app?.renewModalData);
  const isRenewData = renewData === 'organization-api-key';

  React.useEffect(() => {
    fetchOrganizations();
  }, []);

  React.useEffect(() => {
    if (isRenewData) {
      fetchOrganizations();
      dispatch(appActions.renewModalData({ key: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRenewData]);

  return (
    <>
      <AppBarMUI position='fixed' className={classes.appBar}>
        {process.env.SHOW_RELEASE_NOTICE === 'true' && (
          <div className='dx-notice-container'>
            <SectionMessage appearance='warning'>
              <div className='dx-maintenance-notice'>{t('maintainNotice')}</div>
            </SectionMessage>
          </div>
        )}
        <Toolbar variant='dense'>
          <Box
            display='flex'
            flexGrow={1}
            justifyContent='space-between'
            width='100%'
            alignItems='center'
          >
            <div
              className={classes.appBarLeft}
              style={{
                width:
                  windowSize.innerWidth > 830 ? 'auto' : windowSize.innerWidth,
              }}
            >
              <Link
                to='/'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img className={classes.logo} alt='D-Accel' src={logo} />
              </Link>
              {!isFirstAddOrgApiKey && (
                <NavBar
                  windowSize={windowSize}
                  handleLogout={handleLogout}
                  isAccess={isAccess}
                  setIsRefresh={setIsRefresh}
                />
              )}
            </div>
            {windowSize.innerWidth >= 830 && (
              <div className={classes.rightBar}>
                <DropdownMenu
                  isOpen={openDropdown}
                  onOpenChange={(attrs) => {
                    setOpenDropdown(attrs.isOpen);
                  }}
                  trigger={({ triggerRef, ...props }) => (
                    <Button
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      iconBefore={<SettingsIcon size='medium' label='more' />}
                      ref={triggerRef}
                      className={classes.settingsItem}
                    />
                  )}
                >
                  <DropdownItemGroup>
                    <DropdownItem
                      onClick={() => {
                        navigate('/admin-center/organization-management', {
                          replace: true,
                        });
                      }}
                    >
                      <OfficeBuildingIcon
                        size='small'
                        className={classes.icon}
                      />
                      <span> {t('organization.organization')} </span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        navigate('/admin-center/user-management', {
                          replace: true,
                        })
                      }
                    >
                      <PeopleGroupIcon size='small' className={classes.icon} />
                      <span> {t('navBar.userManagePage')}</span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        navigate('/admin-center/installed-status', {
                          replace: true,
                        })
                      }
                    >
                      <AddonIcon size='small' className={classes.icon} />
                      <span> {t('navBar.appInstallation')} </span>
                    </DropdownItem>
                    {shouldStorageSettingMenuItemDisplayed ? (
                      <DropdownItem
                        onClick={() =>
                          dispatch(
                            appActions.toggleModal({
                              key: 'user-bucket-setting',
                            }),
                          )
                        }
                      >
                        <PreferencesIcon
                          size='small'
                          className={classes.icon}
                        />
                        <span>
                          {' '}
                          {t(
                            'navBar.auditLogStorageSettingsMenuItemLabel',
                          )}{' '}
                        </span>
                      </DropdownItem>
                    ) : null}
                  </DropdownItemGroup>
                </DropdownMenu>

                {needApiKey && !openDropdown && !isFirstAddOrgApiKey ? (
                  <div className={classes.keyWarning}>
                    <div className={classes.arrowUp} />
                    <SectionMessage appearance='warning'>
                      <div>{t('needUpdateApiKey')}</div>
                    </SectionMessage>
                  </div>
                ) : null}
                <div style={{ marginLeft: 10 }}>
                  <DropdownMenu
                    trigger={({ triggerRef, ...props }) => (
                      <Button
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        iconBefore={
                          <>
                            <UserAvatarCircleIcon
                              size='medium'
                              secondaryColor='#6d6e6f'
                              primaryColor='#fff'
                            />
                          </>
                        }
                        ref={triggerRef}
                      />
                    )}
                  >
                    <DropdownItemGroup>
                      {user?.email && (
                        <div
                          style={{
                            color: '#303952',
                            padding: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PersonIcon size='medium' /> {user?.email}
                        </div>
                      )}
                      <DropdownItem
                        onClick={handleLogout}
                        isDisabled={!isAccess}
                        style={{ color: '#303952' }}
                      >
                        <SignOutIcon size='small' className={classes.icon} />
                        <span style={{ fontSize: 16 }}>
                          {' '}
                          {t('navBar.signOut')}{' '}
                        </span>
                      </DropdownItem>
                    </DropdownItemGroup>
                  </DropdownMenu>
                </div>
              </div>
            )}
          </Box>
        </Toolbar>
      </AppBarMUI>
      {isOpen !== '' && (
        <ModalInfo
          isOpen={isOpen}
          changedOrg={() => setIsRefresh(true)}
          setIsOpen={setIsOpen}
          siteAdmin={siteAdmin}
        />
      )}
    </>
  );
};
export default AppBar;
