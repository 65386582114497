import { backEndApi } from '@root/libs';

const api = '/api/v1';
const securityPath = '/security';
const securityAPIs = {
  getProjectsInformation: ({ siteIds }) =>
    backEndApi.get(`${api}${securityPath}/projects?siteIds=${siteIds}`),
  renewProjectsData: (body) =>
    backEndApi.post(`${api}${securityPath}/projects/renew`, body),
  changeAccessLevelDashboards: (data) =>
    backEndApi.put(
      `${api}${securityPath}/dashboards/change-access-level`,
      data,
    ),
  changeAccessLevelFilters: (data) =>
    backEndApi.put(`${api}${securityPath}/filter/change-access-level`, data),
  getDashBoardInformation: ({ siteIds }) =>
    backEndApi.get(`${api}${securityPath}/dashboards?siteIds=${siteIds}`),
  renewDashBoardsData: (body) =>
    backEndApi.post(`${api}${securityPath}/dashboards/renew`, body),
  getFilterInformation: (body) =>
    backEndApi.post(`${api}${securityPath}/filters`, body),
  getPublicFilterInformation: (body) =>
    backEndApi.post(`${api}${securityPath}/filters/access-level/public`, body),
  countAccessLevel: (body) =>
    backEndApi.post(`${api}${securityPath}/filters/countAccessLevel`, body),
  renewFiltersData: (body) =>
    backEndApi.post(`${api}${securityPath}/filters/renew`, body),
  updateDashboardAccessLevel: (body) =>
    backEndApi.put(`${api}${securityPath}/dashboard/update-access-level`, body),
  updateFilterAccessLevel: (body) =>
    backEndApi.put(`${api}${securityPath}/filter/update-access-level`, body),
};
export default securityAPIs;
