import { ADMIN_DOMAIN, isSiteDisable } from '@root/utils';
import _ from 'lodash';
import moment from 'moment';

export const formatDate = ({ date, format = 'YYYY/MM/DD HH:mm' }) => {
  if (!date) return '';
  return moment(date).format(format);
};

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const getModalStatus = (modals, key) => {
  const modal = modals.find((m) => m.key === key);
  const isOpen = modal?.state === true;
  return {
    isOpen,
    data: modal?.data,
  };
};

export const checkAllSitesDisable = (sites) => {
  let isAllDisabled = false;
  if (sites) {
    isAllDisabled = sites.every((site) => isSiteDisable(site));
  }
  return isAllDisabled;
};

/**
 * Cleans an object by removing invalid values (e.g., null, undefined, empty strings)
 * and recursively processing nested objects.
 *
 * @param {Object} object - The object to be cleaned.
 * @returns {Object} - A new object containing only valid values.
 *                     Nested objects are also cleaned recursively.
 *
 * const obj = { a: '', b: null, c: { d: '', e: 'valid' } };
 * const cleanedObj = cleanObjectValues(obj);
 * // Result: { c: { e: 'valid' } }
 */
export const cleanObjectValues = (object) => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }

  const cleanedObject = Object.entries(object).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      const cleanedSubObject = cleanObjectValues(value);
      if (Object.keys(cleanedSubObject).length > 0) {
        acc[key] = cleanedSubObject;
      }
    } else if (value != null && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {});

  return cleanedObject;
};

export const isAdminEmail = (email) =>
  ADMIN_DOMAIN.some((domain) => email.includes(domain));
