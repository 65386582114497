/* eslint-disable no-nested-ternary */
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AdminCenter from '@root/components/layout/AdminCenter';
import VerifyEmail from '@root/components/AuthPages/VerifyEmail';
import VerifySuccess from '@root/components/AuthPages/VerifySuccess';
import Signup from '@root/components/AuthPages/Signup';
import DXLoadingScreen from '@root/components/common/loadingIndicator/DXLoadingScreen';
import UserManagement from '@root/components/UserManagement';
import ForgotPassword from '../components/AuthPages/ForgotPassword';
import Login from '../components/AuthPages/Login';
import WrapperComponent from '../components/AuthPages/WrapperComponent';
import { LogginCheckStatus, SignupCheckStatus } from '../utils';

const AuditLog = React.lazy(() => import('../components/AuditLog'));
const Security = React.lazy(() => import('../components/security/Security'));
const ProductStatistic = React.lazy(() =>
  import('@root/components/Activity/product-statistic'),
);
const UserInventory = React.lazy(() =>
  import('@root/components/Activity/user-inventory'),
);
const UserActivity = React.lazy(() =>
  import('@root/components/Activity/user-activity'),
);
const IpAllowList = React.lazy(() => import('@root/components/IpAllowList'));
const RecoveryCode = React.lazy(() =>
  import('@root/components/recoveryCode/RecoveryCode'),
);
const Notification = React.lazy(() => import('@root/components/Notification'));
const InstallManager = React.lazy(() =>
  import('@root/components/InstallManager'),
);
const OrganizationApikeyRegister = React.lazy(() =>
  import('@root/components/organization/OrganizationApikeyRegister'),
);

const OrgManagement = React.lazy(() => import('@root/components/organization'));

const AutoRevoleSetting = React.lazy(() =>
  import('@root/components/AutoRevokeSetting'),
);

const AppRoutes = ({
  loggedIn,
  signup,
  setSignup,
  isRefresh,
  setIsRefresh,
  siteAdmin,
}) => {
  const currentLocation = useLocation();
  return (
    <Routes>
      <Route
        path='/admin-center/security'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <Security isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/admin-center/audit-log'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <AuditLog isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/admin-center/ip-allowlist'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <IpAllowList isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/admin-center/activity/product-data-statistic'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <ProductStatistic
                isRefresh={isRefresh}
                setIsRefresh={setIsRefresh}
              />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/admin-center/activity/user-inventory'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <UserInventory
                isRefresh={isRefresh}
                setIsRefresh={setIsRefresh}
              />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/admin-center/activity/user-activity'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <UserActivity isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/login'
        element={
          <WrapperComponent>
            <Login signup={signup} />
          </WrapperComponent>
        }
      />
      <Route
        path='/signup'
        element={
          <WrapperComponent>
            <Signup signup={signup} setSignup={setSignup} />
          </WrapperComponent>
        }
      />
      <Route
        path='/verify-email/:userId'
        element={
          loggedIn === LogginCheckStatus.FAIL &&
          signup === SignupCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : loggedIn === LogginCheckStatus.SUCCESS ? (
            <Navigate to='/' replace state={{ from: currentLocation }} />
          ) : (
            <WrapperComponent>
              <VerifyEmail setSignup={setSignup} />
            </WrapperComponent>
          )
        }
      />
      <Route
        path='/verify-success'
        element={
          loggedIn === LogginCheckStatus.FAIL &&
          (signup === SignupCheckStatus.FAIL ||
            signup === SignupCheckStatus.NOT_CHECK_YET) ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : loggedIn === LogginCheckStatus.SUCCESS ? (
            <Navigate to='/' replace state={{ from: currentLocation }} />
          ) : (
            <WrapperComponent>
              <VerifySuccess />
            </WrapperComponent>
          )
        }
      />
      <Route
        path='/register-organization-api-key'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : !document.referrer.includes('/login') &&
            window.location.pathname !== '/register-organization-api-key' ? (
            <Navigate to='/' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <OrganizationApikeyRegister
                signup={signup}
                setSignup={setSignup}
              />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/forgotPassword'
        element={
          <WrapperComponent>
            <ForgotPassword />
          </WrapperComponent>
        }
      />
      <Route
        path='/'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <AdminCenter siteAdmin={siteAdmin} />
            </React.Suspense>
          )
        }
      />

      <Route
        path='/recovery-code-management'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <RecoveryCode />
            </React.Suspense>
          )
        }
      />

      <Route
        path='/admin-center/notification'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <Notification isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
            </React.Suspense>
          )
        }
      />

      <Route
        path='/admin-center/installed-status'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <InstallManager />
            </React.Suspense>
          )
        }
      />

      <Route
        path='/admin-center/organization-management'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <OrgManagement
                isRefresh={isRefresh}
                setIsRefresh={setIsRefresh}
              />
            </React.Suspense>
          )
        }
      />
      <Route
        path='/admin-center/user-management'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <UserManagement
                isRefresh={isRefresh}
                setIsRefresh={setIsRefresh}
              />
            </React.Suspense>
          )
        }
      />

      <Route
        path='/admin-center/auto-product-access-revoke-settings'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <React.Suspense fallback={<DXLoadingScreen />}>
              <AutoRevoleSetting />
            </React.Suspense>
          )
        }
      />

      <Route
        path='*'
        element={
          loggedIn === LogginCheckStatus.FAIL ? (
            <Navigate to='/login' replace state={{ from: currentLocation }} />
          ) : (
            <Navigate to='/' replace state={{ from: currentLocation }} />
          )
        }
      />
    </Routes>
  );
};

export default AppRoutes;
