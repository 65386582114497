import i18next from 'i18next';
import React from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import {
  AtlassianIcon,
  BitbucketIcon,
  CompassIcon,
  ConfluenceIcon,
  HalpIcon,
  JiraIcon,
  OpsgenieIcon,
  StatuspageIcon,
} from '@atlaskit/logo';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const EDITOR_COMMENT_APPEARANCE = 'comment';
export const EDITOR_CHROMELESS_APPEARANCE = 'chromeless';
export const START_DATE = 'START_DATE';
export const ENTER_KEYCODE = 13;
export const DAYS_MAX = 1825;
export const MONTHS_MAX = 60;
export const LIMIT_USERS = 2147483647;

export const LogType = {
  ORGANIZATION_LOG: 'ORGANIZATION_LOG',
  SITE_JIRA_LOG: 'SITE_JIRA_LOG',
  SITE_CONFLUENCE_LOG: 'SITE_CONFLUENCE_LOG',
};

export const S3Error = {
  FOLDER_NOT_EXIST: 'FOLDER_NOT_EXIST',
  BUCKET_NOT_EXIST: 'BUCKET_NOT_EXIST',
};

export const PermissionAction = {
  DELETE_ISSUES: 'DELETE_ISSUES',
  EDIT_ISSUES: 'EDIT_ISSUES',
  ADD_COMMENTS: 'ADD_COMMENTS',
  CREATE_ATTACHMENTS: 'CREATE_ATTACHMENTS',
};

// Sắp xếp theo order
export const productKeys = [
  'jira_software',
  'jira_service_desk',
  'jira_business',
  'jira_product_discovery',
  'confluence',
];

export const LIST_ACTIVITY_PRODUCTS = {
  jira_business: {
    key: 'jira_business',
    name: 'activity.jiraWorkManagement',
  },
  jira_core: {
    key: 'jira_core',
    name: 'activity.jiraWorkManagement',
  },
  jira_software: {
    key: 'jira_software',
    name: 'activity.jiraSoftware',
  },
  jira_service_desk: {
    key: 'jira_software',
    name: 'activity.jiraServiceManagement',
  },
  jira_product_discovery: {
    key: 'jira_product_discovery',
    name: 'activity.jiraProductDiscovery',
  },
  confluence: {
    key: 'confluence',
    name: 'activity.confluence',
  },
};

export const ROWS_PER_PAGE_OPTS = [50, 100];

export const ACTIVITY_FILTER_OPTS = () => [
  {
    name: i18next.t('activity.active'),
    value: 'active',
  },
  {
    name: i18next.t('activity.inactive'),
    value: 'inactive',
  },
];
export const STATUS_FILTER_OPTS = () => [
  {
    name: i18next.t('activity.userStatusActive'),
    value: 'active',
  },
  {
    name: i18next.t('activity.userStatusSuspended'),
    value: 'inactive',
  },
  {
    name: i18next.t('activity.userStatusDeleted'),
    value: 'deleted',
  },
];
export const PRODUCTACCESS_FILTER_OPTS = () => [
  {
    name: i18next.t('activity.hasProductAccess'),
    value: true,
  },
  {
    name: i18next.t('activity.noProductAccess'),
    value: false,
  },
];
export const PROJECT_TYPE_FILTER_OPTS = () => [
  {
    name: i18next.t('security.teamManaged'),
    value: 'next-gen',
  },
  {
    name: i18next.t('security.companyManaged'),
    value: 'classic',
  },
];

export const SPACE_TYPE_FILTER_OPTS = () => [
  {
    name: i18next.t('security.shareSpaceType'),
    value: 'global',
  },
  {
    name: i18next.t('security.personalSpaceType'),
    value: 'personal',
  },
];

export const ACCESS_LEVEL_OPTIONS = () => [
  {
    label: i18next.t('siteManagement.site.allAccessLevel'),
    value: 'all',
  },
  {
    label: i18next.t('siteManagement.site.public'),
    value: 'public',
  },
  {
    label: i18next.t('siteManagement.site.open'),
    value: 'open',
  },
  {
    label: i18next.t('siteManagement.site.private'),
    value: 'private',
  },
];

export const ACCESS_SEARCH_OPTIONS = () => [
  {
    label: i18next.t('siteManagement.search.name'),
    value: 'name',
  },
  {
    label: i18next.t('siteManagement.search.organization'),
    value: 'organization',
  },
  {
    label: i18next.t('siteManagement.search.site'),
    value: 'site',
  },
];

export const ACCESS_ROLE_OPTIONS = () => [
  {
    name: i18next.t('security.projectDetail.hasAccess'),
    value: true,
  },
  {
    name: i18next.t('security.projectDetail.noAccess'),
    value: false,
  },
];

export const emptyTable = () => (
  <div
    style={{
      color: '#45526C',
      fontWeight: 'normal',
      fontSize: '20px',
    }}
  >
    {i18next.t('activity.emptyDataTable')}
  </div>
);

export const PROJECT_TYPE = ['business', 'service_desk', 'software'];

export const DEFAULT_ROW_PER_PAGE = 10;
export const DEFAULT_ROW_PER_PAGE_OPTIONS = () => ({
  label: `${DEFAULT_ROW_PER_PAGE} / ${i18next.t('activity.page')}`,
  value: DEFAULT_ROW_PER_PAGE,
});
export const ROW_PER_PAGE_OPTIONS = () => [
  DEFAULT_ROW_PER_PAGE_OPTIONS(),
  { label: `20 / ${i18next.t('activity.page')}`, value: 20 },
  { label: `50 / ${i18next.t('activity.page')}`, value: 50 },
  { label: `100 / ${i18next.t('activity.page')}`, value: 100 },
];

export const LogginCheckStatus = {
  NOT_CHECK_YET: 'NOT_CHECK_YET',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const SignupCheckStatus = {
  NOT_CHECK_YET: 'NOT_CHECK_YET',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const errorMessageLicense = () => (
  <div
    style={{
      height: '100%',
      justifyContent: 'center',
      color: '#6D6E6F',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <WarningIcon primaryColor='#E5686F' />
    {i18next.t('common.licenseOutdate')}
  </div>
);

export const isSiteDisable = (site) => {
  let isDisable = true;
  if (site?.isJiraEnabled && site?.isConfluenceEnabled) {
    isDisable = !site.isJiraLicense || !site.isConfluenceLicense;
  } else if (site?.isJiraEnabled && !site?.isConfluenceEnabled) {
    isDisable = !site.isJiraLicense;
  } else if (!site?.isJiraEnabled && site?.isConfluenceEnabled) {
    isDisable = !site.isConfluenceLicense;
  }
  return isDisable;
};
export const DEAFAULT_SEARCH_TYPE = 'name';
export const ACCESS_LEVEL = {
  ALL: 'all',
  PRIVATE: 'private',
  PUBLIC: 'public',
  OPEN: 'open',
  PERMISSION: 'permission',
};

export const ACTION_USER_TYPE_CONFIRM = {
  REMOVE_USER: 'remove',
  RESET_DEVICE_MFA: 'resetDeviceMfa',
  TURN_OFF_MFA: 'turnOffMfa',
  CANCEL_MODAL: 'cancel',
};

export const SECURITY_TABLE_NAME = {
  JIRA_PROJECT: 'project',
  JIRA_DASHBOARD: 'dashboard',
  JIRA_FILTER: 'filter',
  JIRA_PUBLIC_SHARE: 'publicShare',
  CONFLUENCE_SPACE: 'space',
};

export const PAGINATION_MAX_PAGE = 3;

export const PLAN_KEY = {
  free: 'free',
  standard: 'standard',
  premium: 'premium',
  enterprise: 'enterprise',
  sandboxpremium: 'sandboxpremium',
  sandboxenterprise: 'sandboxenterprise',
};

const IconComponent = ({ Icon }) => (
  <Icon appearance='brand' iconColor='#2684FF' />
);

export const PRODUCT_LOGO = ({ type }) => {
  switch (type) {
    case 'jira':
      return <IconComponent Icon={JiraIcon} />;
    case 'confluence':
      return <IconComponent Icon={ConfluenceIcon} />;
    case 'bitbucket':
      return <IconComponent Icon={BitbucketIcon} />;
    case 'compass':
      return <IconComponent Icon={CompassIcon} />;
    case 'halp':
      return <IconComponent Icon={HalpIcon} />;
    case 'opsgenie':
      return <IconComponent Icon={OpsgenieIcon} />;
    case 'statuspage':
      return <IconComponent Icon={StatuspageIcon} />;
    case 'atlassian':
      return <IconComponent Icon={AtlassianIcon} />;
    default:
      return <IconComponent Icon={AtlassianIcon} />;
  }
};

export const NOTIFICATION_FEATURE = {
  JIRA_SYSTEM: 'jira_public_sharing',
  JIRA_PROJECT: 'jira_project',
  JIRA_DASHBOARD: 'jira_dashboard',
  JIRA_FILTER: 'jira_filter',
  CONFLUENCE_SPACE: 'confluence_space',
  PRODUCT_CHANGE: 'product_change',
  PLUGIN_CHANGE: 'plugin_change',
};

export const NOTIFICATION_ACCESS_LEVEL = {
  PUBLIC: 'public',
  OPEN: 'open',
  WARNING: 'warning',
};

export const ADDON_INSTALL_STATUS = {
  NOT_INSTALLED: 'not_installed',
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not_connected',
  INSTALLING: 'installing',
  INSTALL_FAILED: 'install_failed',
};

export const ADDON_INSTALL_PRODUCT = {
  JIRA: 'jira',
  CONFLUENCE: 'confluence',
  JIRA_SOFTWARE: 'jira-software',
  JIRA_SERVICE_DESK: 'jira-service-desk',
  JIRA_SERVICEDESK: 'jira-servicedesk', // the same as jira-service_desk
  JIRA_BUSINESS: 'jira-business',
  JIRA_CORE: 'jira-core', // the same as jira-business
  JIRA_PRODUCT_DISCOVERY: 'jira-product-discovery',
};

export const ADMIN_DOMAIN = process.env.ADMIN_EMAIL_DOMAIN.split(',');

export const MAX_CONCURRENT_USERS_EXCUTED = 5000;
export const FILTER_BATCH_SIZE = 100;
export const FILTER_CONCURRENCY_LIMIT = 3;

export const MAX_CONCURRENT_FILTERS_EXECUTED = 5000;

export const MANAGE_ACCESS_USER_ACTION = {
  REVOKE_PRODUCT_ACCESS: 'revoke-product-access',
  SUSPEND_USER_ACCESS: 'suspend-user-access',
  REMOVE_USER: 'remove-user',
};

export const REVOKE_PRODUCT_ACCESS_MODE = {
  MANUAL: 'manual',
  AUTO: 'auto',
};

export const CHANGE_OWNER_SELECTION = {
  ALL: 'all',
  THIS: 'this page',
  NONE: 'none',
};

export const TRANSFER_STATUS = {
  TRANSFERRING: 'transferring',
  LAUNCHING: 'launching',
  PREPARING: 'preparing',
  VERIFYING: 'verifying',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const ViewTypeComponent = {
  home: 'home',
  security: 'security',
  collaboration: 'collaboration',
};

export const PROD_ENV_VALUE = {
  APP_KEY_JIRA: 'jp.ricksoft.dx.jira.daccel',
  BASE_URL: 'https://app.daccel.jp',
};
export const ValueCheckAllOption = 'ALL';
export const checkAllOption = () => ({
  label: i18next.t('common.selectAll'),
  value: ValueCheckAllOption,
  isDisabled: false,
});
export const optionsAppliedTypes = () => [
  { value: 'organization', label: i18next.t('autoRevokeSetting.orgs') },
  { value: 'site', label: i18next.t('autoRevokeSetting.sites') },
];

export const optionsActivityDuration = () => [
  { value: 1, label: i18next.t('activity.last1Month') },
  { value: 3, label: i18next.t('activity.last3Months') },
  { value: 6, label: i18next.t('activity.last6Months') },
  { value: 9, label: i18next.t('activity.last9Months') },
  { value: 12, label: i18next.t('activity.last1Year') },
  { value: 24, label: i18next.t('activity.last2Years') },
];

export const optionsWeek = () => [
  { value: 1, label: i18next.t('autoRevokeSetting.firstWeek') },
  { value: 2, label: i18next.t('autoRevokeSetting.secondWeek') },
  { value: 3, label: i18next.t('autoRevokeSetting.thirdWeek') },
  { value: 4, label: i18next.t('autoRevokeSetting.fourthWeek') },
  { value: 5, label: i18next.t('autoRevokeSetting.lastWeek') },
];

export const optionsDay = () => [
  { value: 'fisrt_day_month', label: i18next.t('autoRevokeSetting.fisrtDay') },
  { value: 'last_day_month', label: i18next.t('autoRevokeSetting.lastDay') },
  { value: '2', label: i18next.t('autoRevokeSetting.monday') },
  { value: '3', label: i18next.t('autoRevokeSetting.tuesday') },
  { value: '4', label: i18next.t('autoRevokeSetting.wednesday') },
  { value: '5', label: i18next.t('autoRevokeSetting.thursday') },
  { value: '6', label: i18next.t('autoRevokeSetting.friday') },
  { value: '7', label: i18next.t('autoRevokeSetting.saturday') },
  { value: '1', label: i18next.t('autoRevokeSetting.sunday') },
];
export const AUTO_REVOKE_APPLIED_TYPE = {
  ORGANIZATION: 'organization',
  SITE: 'site',
};

export const MAX_VISIBLE_DATA = 3;

export const MAX_LENGTH_OF_FARGATE_TASK_PARAMS = 7000;
