import React, { useEffect } from 'react';
import Drawer from '@atlaskit/drawer';
import DepartmentIcon from '@atlaskit/icon/glyph/department';
import HomeIcon from '@atlaskit/icon/glyph/home';
import LockIcon from '@atlaskit/icon/glyph/lock';
import MenuIcon from '@atlaskit/icon/glyph/menu';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import NotificationIcon from '@atlaskit/icon/glyph/notification';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { makeStyles, styled } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import noop from 'lodash/noop';
import ModalInfo from '../../app/ModalInfo';

const MainNavLink = styled(NavLink)({
  color: '#1E1F21 !important',
  textUnderlineOffset: '12px',
  textDecoration: 'none',
  fontWeight: '600',
  boxSizing: 'border-box',
  marginRight: '5px',
  borderBottom: '1px solid transparent',
  '&.active': {
    textDecoration: 'underline',
  },
});
const useStyles = makeStyles(() => ({
  navItem: {
    color: '#6D6E6F',
    textDecoration: 'none',
    fontWeight: '600',
    boxSizing: 'border-box',
    marginRight: '5px',
    borderBottom: '1px solid transparent',
  },
  disableItem: {
    color: '#abb0bb',
  },
  out: {
    marginRight: '3px',
    position: 'relative',
    width: 0,
    height: 0,
    borderTop: '16px solid transparent',
    borderRight: '16px solid #D4DEE2',
    borderBottom: '16px solid transparent',
  },
  in: {
    position: 'absolute',
    top: '-13px',
    right: '-16px',
    width: 0,
    height: 0,
    borderTop: '13px solid transparent',
    borderRight: '13px solid white',
    borderBottom: '13px solid transparent',
  },
  titleMenu: {
    padding: '10px 12px',
    fontSize: '13px',
    '&:hover': {
      color: '#1E1F21',
      textDecoration: 'underline',
      textUnderlineOffset: '12px',
      'border-radius': '3px',
    },
    cursor: 'pointer',
  },
  active: {
    textDecoration: 'underline',
  },
  menuIcon: {
    cursor: 'pointer',
    '&:hover': {
      background: '#D8D8D8',
      'border-radius': '3px',
    },
  },
  '@media screen and (max-width: 830px)': {
    titleMenu: {
      padding: '1rem !important',
      '&:hover': {
        'background-color': '#F2F2F2',
      },
    },
    navItem: {
      padding: 0,
    },
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { windowSize, handleLogout, isAccess, setIsRefresh } = props;
  const urlParams = useSelector((state) => state?.activity?.urlParams ?? '');

  const siteAdmin = useSelector((state) =>
    state.site.siteList.filter(
      (s) => s.isAdmin === true || s.isAdmin === 'true',
    ),
  );
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchSiteIdQuery, setSearchSiteIdQuery] = React.useState('');

  useEffect(() => {
    const params = new URLSearchParams(urlParams);
    if (!urlParams) {
      return;
    }
    const queryString = `?${params.toString()}`;
    setSearchQuery(queryString);
    const siteId = params.get('siteId');
    if (siteId && siteId !== 'null') {
      setSearchSiteIdQuery(`?siteId=${siteId}`);
    }
  }, [JSON.stringify(urlParams)]);

  const handleOpenDialog = (key) => {
    setIsOpen(key);
  };

  if (!location.pathname) {
    return null;
  }

  const renderDrawer = () => (
    <Drawer
      onClose={() => setOpenDrawer(false)}
      isOpen={openDrawer}
      width='20'
      overrides={{
        Sidebar: {
          component: () => <div style={{ width: 0 }} />,
        },
        Content: {
          component: () => (
            <div
              style={{
                marginTop: 45,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <NavLink
                end
                className={classes.navItem}
                style={() => ({
                  width: '100%',
                  textDecoration: 'none',
                  color: '#3867d6',
                  borderBottom: '3px solid #3867d6',
                })}
                to='/'
              >
                <div
                  className={classes.titleMenu}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <HomeIcon size='small' />
                  <div style={{ marginLeft: 8 }}>{t('navBar.homePage')}</div>
                </div>
              </NavLink>
              {location.pathname === '/collaboration-center' && (
                <NavLink
                  end
                  className={classes.navItem}
                  style={() => ({
                    width: '100%',
                    textDecoration: 'none',
                    color: '#3867d6',
                    borderBottom: '3px solid #3867d6',
                  })}
                  to='/collaboration-center'
                >
                  <div
                    className={classes.titleMenu}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <LockIcon size='small' />
                    <div style={{ marginLeft: 8 }}>
                      {t('navBar.collaborationCenter')}
                    </div>
                  </div>
                </NavLink>
              )}
              {location.pathname.includes('/admin-center/') && (
                <>
                  <NavLink
                    end
                    className={classes.navItem}
                    style={() => ({
                      width: '100%',
                      textDecoration: 'none',
                      color: '#3867d6',
                      borderBottom: '3px solid #3867d6',
                    })}
                    to={{
                      pathname: '/admin-center/activity/product-data-statistic',
                      search: searchQuery,
                    }}
                  >
                    <div
                      className={classes.titleMenu}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LockIcon size='small' />
                      <div style={{ marginLeft: 8 }}>
                        {t('navBar.activityProductStatistic')}
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    end
                    className={classes.navItem}
                    style={() => ({
                      width: '100%',
                      textDecoration: 'none',
                      color: '#3867d6',
                      borderBottom: '3px solid #3867d6',
                    })}
                    to={{
                      pathname: '/admin-center/activity/user-activity',
                      search: searchQuery,
                    }}
                  >
                    <div
                      className={classes.titleMenu}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LockIcon size='small' />
                      <div style={{ marginLeft: 8 }}>
                        {t('navBar.userActivity')}
                      </div>
                    </div>
                  </NavLink>

                  <NavLink
                    end
                    className={classes.navItem}
                    style={() => ({
                      width: '100%',
                      textDecoration: 'none',
                      color: '#3867d6',
                      borderBottom: '3px solid #3867d6',
                    })}
                    to={{
                      pathname: '/admin-center/activity/user-inventory',
                      search: searchQuery,
                    }}
                  >
                    <div
                      className={classes.titleMenu}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LockIcon size='small' />
                      <div style={{ marginLeft: 8 }}>
                        {t('navBar.activityUserInventory')}
                      </div>
                    </div>
                  </NavLink>
                </>
              )}
              {location.pathname === '/solution-center' && (
                <>
                  <NavLink
                    end
                    activeclassname={classes.active}
                    className={classes.navItem}
                    style={() => ({
                      width: '100%',
                      textDecoration: 'none',
                      color: '#3867d6',
                      borderBottom: '3px solid #3867d6',
                    })}
                    to='/solution-center'
                  >
                    <div
                      className={classes.titleMenu}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LockIcon size='small' />
                      <div style={{ marginLeft: 8 }}>
                        {t('navBar.solutionCenter')}
                      </div>
                    </div>
                  </NavLink>
                </>
              )}
              {location.pathname.includes('/admin-center/') && (
                <NavLink
                  end
                  className={classes.navItem}
                  style={() => ({
                    width: '100%',
                    textDecoration: 'none',
                    color: '#3867d6',
                    borderBottom: '3px solid #3867d6',
                  })}
                  to={{
                    pathname: '/admin-center/security',
                    search: searchSiteIdQuery,
                  }}
                >
                  <div
                    className={classes.titleMenu}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <LockIcon size='small' />
                    <div style={{ marginLeft: 8 }}>{t('navBar.security')}</div>
                  </div>
                </NavLink>
              )}
              {location.pathname.includes('/admin-center/') && (
                <NavLink
                  end
                  className={classes.navItem}
                  style={() => ({
                    width: '100%',
                    textDecoration: 'none',
                    color: '#3867d6',
                    borderBottom: '3px solid #3867d6',
                  })}
                  to='/admin-center/audit-log'
                >
                  <div
                    className={classes.titleMenu}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <LockIcon size='small' />
                    <div style={{ marginLeft: 8 }}>
                      {t('navBar.auditLogPage')}
                    </div>
                  </div>
                </NavLink>
              )}
              {location.pathname.includes('/admin-center/') && (
                <NavLink
                  end
                  className={classes.navItem}
                  style={() => ({
                    width: '100%',
                    textDecoration: 'none',
                    color: '#3867d6',
                    borderBottom: '3px solid #3867d6',
                  })}
                  to='/admin-center/ip-allowlist'
                >
                  <div
                    className={classes.titleMenu}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <LockIcon size='small' />
                    <div style={{ marginLeft: 8 }}>
                      {t('navBar.ipAllowList')}
                    </div>
                  </div>
                </NavLink>
              )}
              {siteAdmin.length > 0 && (
                <NavLink
                  end
                  className={classes.navItem}
                  style={() => ({
                    width: '100%',
                    textDecoration: 'none',
                    color: '#3867d6',
                    borderBottom: '3px solid #3867d6',
                  })}
                  to='/admin-center/notification'
                >
                  <div
                    className={classes.titleMenu}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <NotificationIcon size='small' />
                    <div style={{ marginLeft: 8 }}>
                      {t('navBar.notification')}
                    </div>
                  </div>
                </NavLink>
              )}
              {siteAdmin.length > 0 && (
                <div
                  role='presentation'
                  className={`${classes.navItem} ${classes.titleMenu} `}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => handleOpenDialog('organization')}
                >
                  <DepartmentIcon size='small' />
                  <div style={{ marginLeft: 8 }}>
                    {t('organization.organization')}
                  </div>
                </div>
              )}
              {siteAdmin.length > 0 && (
                <NavLink
                  end
                  className={classes.navItem}
                  style={() => ({
                    width: '100%',
                    textDecoration: 'none',
                    color: '#3867d6',
                    borderBottom: '3px solid #3867d6',
                  })}
                  to='/admin-center/installed-status'
                >
                  <AddonIcon size='small' />
                  <div style={{ marginLeft: 8 }}>
                    {t('navBar.appInstallation')}
                  </div>
                </NavLink>
              )}
              {siteAdmin.length > 0 && (
                <div
                  role='menuitem'
                  tabIndex={0}
                  onKeyPress={noop}
                  className={`${classes.navItem} ${classes.titleMenu} `}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => handleOpenDialog('user_management')}
                >
                  <PeopleGroupIcon size='small' />
                  <div style={{ marginLeft: 8 }}>
                    {t('navBar.userManagePage')}
                  </div>
                </div>
              )}
              {siteAdmin.length > 0 && (
                <NavLink
                  end
                  className={classes.navItem}
                  style={() => ({
                    width: '100%',
                    textDecoration: 'none',
                    color: '#3867d6',
                    borderBottom: '3px solid #3867d6',
                  })}
                  to='/admin-center/auto-revoke-product-access-settings'
                >
                  <div
                    className={classes.titleMenu}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <SettingsIcon size='small' />
                    <div style={{ marginLeft: 8 }}>
                      {t('navBar.autoRevokeSetting')}
                    </div>
                  </div>
                </NavLink>
              )}
              <div
                role='menuitem'
                tabIndex={0}
                onKeyPress={noop}
                className={`${classes.navItem} ${classes.titleMenu} `}
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => {
                  if (!isAccess) {
                    return;
                  }
                  handleLogout();
                }}
              >
                <SignOutIcon size='small' />
                <div style={{ marginLeft: 8 }}>{t('navBar.signOut')}</div>
              </div>
            </div>
          ),
        },
      }}
    />
  );

  return (
    <>
      {windowSize.innerWidth < 830 ? (
        <>
          {renderDrawer()}
          <div
            role='presentation'
            className={classes.menuIcon}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon size='medium' primaryColor='#6E6E6E' />
          </div>
        </>
      ) : (
        <>
          <MainNavLink end to='/'>
            <div
              className={classes.titleMenu}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ marginLeft: 3 }}>{t('navBar.homePage')}</span>
            </div>
          </MainNavLink>
          {location.pathname.includes('/admin-center/') && (
            <>
              <span className={classes.out}>
                <span className={classes.in} />
              </span>
              <MainNavLink
                end
                to={{
                  pathname: '/admin-center/security',
                  search: searchSiteIdQuery,
                }}
              >
                <span className={classes.titleMenu}>
                  {t('navBar.security')}
                </span>
              </MainNavLink>
            </>
          )}
          {location.pathname.includes('/admin-center/') && (
            <MainNavLink end to='/admin-center/audit-log'>
              <span className={classes.titleMenu}>
                {t('navBar.auditLogPage')}
              </span>
            </MainNavLink>
          )}
          {location.pathname.includes('/admin-center/') && (
            <>
              {' '}
              <MainNavLink
                end
                to={{
                  pathname: '/admin-center/activity/product-data-statistic',
                  search: searchQuery,
                }}
              >
                <div
                  className={classes.titleMenu}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ marginLeft: 8 }}>
                    {t('navBar.activityProductStatistic')}
                  </div>
                </div>
              </MainNavLink>
              <MainNavLink
                end
                to={{
                  pathname: '/admin-center/activity/user-activity',
                  search: searchQuery,
                }}
              >
                <div
                  className={classes.titleMenu}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ marginLeft: 8 }}>
                    {t('navBar.userActivity')}
                  </div>
                </div>
              </MainNavLink>
              <MainNavLink
                end
                to={{
                  pathname: '/admin-center/activity/user-inventory',
                  search: searchQuery,
                }}
              >
                <div
                  className={classes.titleMenu}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ marginLeft: 8 }}>
                    {t('navBar.activityUserInventory')}
                  </div>
                </div>
              </MainNavLink>
            </>
          )}
          {location.pathname.includes('/admin-center/') && (
            <MainNavLink end to='/admin-center/ip-allowlist'>
              <span className={classes.titleMenu}>
                {t('navBar.ipAllowList')}
              </span>
            </MainNavLink>
          )}
        </>
      )}
      {isOpen !== '' && (
        <ModalInfo
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          siteAdmin={siteAdmin}
          changedOrg={() => setIsRefresh(true)}
        />
      )}
    </>
  );
};

export default NavBar;
