import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import SearchIcon from '@atlaskit/icon/glyph/search';
import Pagination from '@atlaskit/pagination';
import Popup from '@atlaskit/popup';
import Select from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { Status } from '@atlaskit/status/element';
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import PaginationTotalNumber from '@root/components/common/PaginationTotalNumber';
import { siteAPIs } from '@root/services';
import mfaAPIs from '@root/services/mfaApis';
import { checkAllSitesDisable } from '@root/utils/helpers';
import {
  ACTION_USER_TYPE_CONFIRM,
  emptyTable,
  isSiteDisable,
} from '@root/utils';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { actions } from '../../redux';
import { ALERT_TYPE } from '../common/MessageSnackbar';
import ActionUserConfirmModal from './components/ActionUserConfirmModal';
import InviteUserDialog from './components/InviteUserModal';
import MfaToggle from './components/MfaToggle';
import UserManagerContext, {
  UserManagerDefault,
  fetchCurrentUsers,
} from './components/UserManagerContext';
import { getSiteIds } from '../Activity/common';
import TopFilter from './components/TopFilter';
import { CollapseSection } from '../common/section';
import useSiteList from '../security/useSiteList';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 62px)',
    padding: '0px 0px 20px 0px',
    paddingBottom: '1rem',
    '& table': {
      fontSize: 14,
    },
    '& th': {
      background: '#F2F2F2',
      color: '#000',
      '&:focus': {
        outline: 'solid 2px transparent',
      },
    },
    '& th:first-child, & td:first-child': {
      paddingLeft: 8,
    },
    '& th >span::before, & th >span::after': {
      display: 'none',
    },
    '& .MuiIconButton-sizeSmall': {
      padding: '2px',
    },
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    lineHeight: '26px',
  },
  optionDisable: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableResponsive: {
    '& > table': {
      minWidth: 700,
    },
    '& nav': {
      display: 'none',
    },
  },
  pagTable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
  },
  rowsPerPage: {
    minWidth: 130,
    fontSize: '14px',
    '&:before': {
      display: 'none !important',
    },
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '20px 0',
  },
  spinner: {
    height: '50vh',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navbar: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputForm: {
    width: '300px',
  },
  warning: {
    display: 'flex',
    fontSize: '15px',
    color: '#DE350B',
    paddingTop: '5px',
    paddingBottom: '15px',
    width: '100%',
  },
  title: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '20px',
    color: '#091E42',
    marginBottom: '15px',
  },
  searchContainer: {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  inviteIcon: {
    marginRight: '0',
    width: '60%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterName: {
    marginLeft: '50px',
  },
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& th': {
      fontSize: '14px',
    },
  },
  displayName: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    '& span:nth-child(2)': {
      marginLeft: '3px',
    },
  },
  email: {
    display: 'flex',
  },
  avatar: {
    marginRight: '0.5rem',
  },
  wrapSpinner: {
    display: 'inline-block',
    marginLeft: 16,
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  header: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
  },

  tableHeader: {
    display: 'flex',
    width: '10px',
    backgroundColor: 'none',
  },
  sortableIcon: {
    marginTop: 4,
    '&:before': {
      border: '3px solid transparent',
      display: 'block',
      height: 0,
      width: 0,
      borderBottom:
        '3px solid var(--ds-background-subtleNeutral-resting,#DFE1E6)',
      content: '""',
    },
    '&:after': {
      border: '3px solid transparent',
      display: 'block',
      height: 0,
      right: -8,
      width: 0,
      marginTop: 2,
      borderTop: '3px solid var(--ds-background-subtleNeutral-resting,#DFE1E6)',
      bottom: 0,
      content: '""',
    },
    '&.sort-ASC:before': {
      borderBottom: '3px solid var(--ds-text-lowEmphasis,#5E6C84)',
    },
    '&.sort-DESC:after': {
      borderTop: '3px solid var(--ds-text-lowEmphasis,#5E6C84)',
    },
  },
  titleWrapper: {
    fontSize: '20px',
    fontWeight: 500,
  },
  searchItem: {
    minWidth: 230,
    fontSize: '14px',
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
    '& input[name="keyword"]': {
      height: 36,
    },
  },
  search: {
    width: '75%',
    display: 'flex',
    justifyContent: 'end',
    '& > div:nth-child(2)': {
      marginLeft: '10px',
    },
    '& > div:nth-child(3)': {
      marginLeft: '10px',
    },
  },
  footerStyles: {
    display: 'flex',
    padding: '45px 10px 14px 10px',
    alignItems: 'center',
    justifyContent: 'end',
  },
  activeFilter: {
    '&:not([aria-expanded="true"])': {
      color: '#007BFF !important',
      '& span svg': {
        color: '#007BFF !important',
      },
    },
  },
  jiraHeaderCenter: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minHeight: 40,
    padding: 4,
    borderRight: '2px #e2e2e2 solid',
    cursor: 'pointer',
    gap: '0 5px',
    '&:hover::before': {
      content: '" "',
      width: '100%',
      position: 'absolute',
      background: '#fefefe',
      bottom: '-20px',
      top: '-5px',
      zIndex: -1,
    },
  },
  topActionWrapper: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '0 8px',
    marginBottom: '-15px',
    fontSize: 14,
    color: '#45526C',
    minHeight: 40,
    flexWrap: 'wrap',
    '& p, & button': {
      fontSize: 14,
    },
    '& > .left-actions': {
      display: 'flex',
      alignItems: 'center',
      gap: '0 8px',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#45526C',
    },
  },

  rowsPerPageOpt: {
    minWidth: 130,
    fontSize: '14px',
    '&:before': {
      display: 'none !important',
    },
  },

  searchPopupButton: {
    marginTop: 21,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0 5px',
  },

  searchButtonContainer: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DFE1E6',
    },
  },
  userAtlassianNotActive: {
    opacity: 0.3,
  },
}));

const UsersBoard = (props) => {
  const { defaultRowsPerPage = 10 } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.app?.currentUser);
  const loadingMenu = useSelector((state) => state.site.loadingMenu);

  const { actions: action } = useSiteList();
  const siteListOption = action.getSiteOptions();

  const optionSites = siteListOption.map((site) => ({
    label: site.name,
    value: site.siteId,
    isDisabled: isSiteDisable(site),
  }));
  // the currently selected sites after filtering is applied.
  const [sites, setSites] = React.useState(null);
  // the list of sites that are being searched for in the filter dropdown
  const [siteSearching, setSiteSearching] = useState(null);
  // the list of siteIds
  const [siteIdsSearching, setSiteIdsSearching] = useState(null);
  const [userManagemerState, setUserManagerState] =
    useState(UserManagerDefault);
  const { siteUsers } = userManagemerState;

  const [reinvitingAccountId, setReinvitingAccountId] = useState(null);
  // action off MFA, remove user,  reset device MFA
  const [actionConfirmAccount, setActionConfirmAccount] = useState(null);

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [selectedSort, setSelectedSort] = useState({
    key: 'email',
    sortOrder: 'ASC',
  });

  const [userData, setUserData] = useState([]);

  // table pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState({
    label: '',
    value: '',
  });

  // search
  const [searchKeyName, setSearchKeyName] = useState('');
  const [searchKeyEmail, setSearchKeyEmail] = useState('');

  // search Popup
  const [isOpenSearchEmail, setisOpenSearchEmail] = React.useState(false);
  const [searchBy, setSearchBy] = React.useState('');
  const [searchTempValue, setSearchTempValue] = React.useState({
    email: '',
  });

  // notification confirm
  const [notificationConfirm, setNotificationConfirm] = React.useState({
    type: '',
    title: '',
    message: '',
    submitText: '',
  });

  const handleSearchFilter = (siteSearchingList) => {
    const siteIds = siteSearchingList.map((s) => s.value || s.siteId);
    setSiteIdsSearching(siteIds);
    setSites(siteSearchingList);
    setCurrentPage(1);
    if (!sites) {
      newRelicUtils.addPageAction(PAGE_ACTION.userManagement.open, {
        siteIds: getSiteIds(siteSearchingList),
      });
    } else {
      newRelicUtils.addPageAction(PAGE_ACTION.userManagement.changeSite, {
        siteIds: getSiteIds(siteSearchingList),
      });
    }
  };

  const renderLastLoginDate = (lastLoginDate) => {
    const date = new Date(lastLoginDate);

    // Formatted YYYY/MM/DD HH:mm:ss
    const formattedDate = moment(date).format('YYYY/MM/DD HH:mm:ss');
    return formattedDate;
  };

  const loadUsers = async () => {
    setLoadingUsers(true);
    const { siteUsers: fetchSiteUsers } = await fetchCurrentUsers(
      siteIdsSearching,
    );
    setUserManagerState({
      siteUsers: fetchSiteUsers,
    });
    setLoadingUsers(false);
  };

  const handleSearch = () => {
    let tempData = cloneDeep(siteUsers);
    if (searchKeyName.trim().length) {
      tempData = tempData?.filter((item) =>
        item?.name?.toLowerCase().includes(searchKeyName.toLowerCase()),
      );
    }
    if (searchKeyEmail.trim().length) {
      tempData = tempData?.filter((item) =>
        item?.email?.toLowerCase().includes(searchKeyEmail.toLowerCase()),
      );
    }
    setUserData(tempData);
  };

  // add displayName property to siteUser
  // eslint-disable-next-line array-callback-return

  const loadUsersCallback = useCallback(loadUsers, [sites]);

  const onClickInviteBtn = async () => {
    // enable invite in confluence too
    setIsInviteDialogOpen(true);
  };

  const reInviteUser = async (user) => {
    newRelicUtils.addPageAction(PAGE_ACTION.userManagement.reinvite, {
      siteIds: user?.siteId,
      targetIds: user?.userId,
    });
    setReinvitingAccountId(user?.userId);
    try {
      await siteAPIs.resetUserPasswordFromSite(user?.siteId, user?.userId);
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.reinviteSiteUserOk',
        }),
      );
    } catch (reinviteUserError) {
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.error,
          messageKey: 'snackbar.failedToReInviteUser',
        }),
      );
    }
    setReinvitingAccountId(null);
  };

  const handleSortColumn = (key) => {
    if (selectedSort.key === key) {
      setSelectedSort({
        key,
        sortOrder: selectedSort.sortOrder === 'ASC' ? 'DESC' : 'ASC',
      });
    } else {
      setSelectedSort({
        key,
        sortOrder: 'ASC',
      });
    }
  };

  const renderIconSort = (key) => {
    if (selectedSort.key === key) {
      if (selectedSort.sortOrder === 'ASC') {
        return <ArrowDownIcon size='small' />;
      }
      if (selectedSort.sortOrder === 'DESC') {
        return <ArrowUpIcon size='small' />;
      }
    }
    return '';
  };

  const handleReset = (sBy) => {
    switch (sBy) {
      case 'email':
        setSearchKeyEmail('');
        setSearchTempValue({
          ...searchTempValue,
          [sBy]: '',
        });
        break;
      default:
        setSearchKeyEmail('');
        setSearchKeyName('');
        break;
    }
  };

  const handleSearchUser = (e) => {
    const value = e?.currentTarget?.value || searchTempValue[searchBy];
    // if (e?.currentTarget?.value) setSearchTempValue(...);
    if (searchBy === 'email') setSearchKeyEmail(value);
  };

  const renderSearchPopup = ({ setInitialFocusRef }) => (
    <Paper
      elevation={1}
      style={{
        border: 1,
        padding: '15px 15px 25px',
        marginTop: 20,
        minWidth: 250,
      }}
    >
      <Textfield
        ref={setInitialFocusRef}
        defaultValue={searchTempValue[searchBy]}
        onChange={(event) => {
          setSearchTempValue({
            ...searchTempValue,
            [searchBy]: event.target.value,
          });
        }}
        value={searchTempValue[searchBy]}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearchUser(e);
            setisOpenSearchEmail(false);
            setCurrentPage(1);
          }
        }}
        placeholder={
          searchBy === 'name'
            ? t('userManagement.searchPlaceHolderByName')
            : t('userManagement.searchPlaceHolderByEmail')
        }
        autoFocus
      />
      <div className={classes.searchPopupButton}>
        <Button
          appearance='subtle'
          size={14}
          onClick={() => {
            setSearchTempValue({
              ...searchTempValue,
              [searchBy]: '',
            });
            setisOpenSearchEmail(false);
            handleReset(searchBy);
            setCurrentPage(1);
          }}
          isDisabled={!searchTempValue[searchBy]}
        >
          {t('userManagement.reset')}
        </Button>
        <Button
          size='small'
          iconBefore={<SearchIcon size='small' />}
          appearance='primary'
          onClick={() => {
            handleSearchUser();
            setisOpenSearchEmail(false);
            setCurrentPage(1);
          }}
        >
          {t('userManagement.searchBtn')}
        </Button>
      </div>
    </Paper>
  );

  const head = {
    cells: [
      {
        key: 'site',
        content: (
          <div className={classes.jiraHeaderCenter}>
            <span
              style={{ paddingRight: '5px', cursor: 'pointer', flex: 1 }}
              onClick={() => handleSortColumn('siteName')}
            >
              {renderIconSort('siteName')}
              {t('common.atlas.site')}
            </span>
          </div>
        ),
        isSortable: false,
        width: 20,
      },
      {
        key: 'email',
        content: (
          <div className={classes.jiraHeaderCenter}>
            <Tooltip content={t('common.sort')}>
              {(tooltipProps) => (
                <span
                  {...tooltipProps}
                  style={{ paddingRight: '5px', cursor: 'pointer', flex: 1 }}
                  onClick={() => handleSortColumn('email')}
                >
                  {renderIconSort('email')}
                  {t('userManagement.userList.emailHeading')}
                </span>
              )}
            </Tooltip>

            <Popup
              zIndex={999}
              isOpen={isOpenSearchEmail}
              onClose={() => setisOpenSearchEmail(false)}
              placement='bottom-start'
              content={({ setInitialFocusRef }) =>
                renderSearchPopup({ setInitialFocusRef })
              }
              trigger={(triggerProps) => (
                <div
                  {...triggerProps}
                  className={`${classes.searchButtonContainer} ${
                    searchTempValue.email && classes.activeFilter
                  }`}
                  disabled={!!(isOpenSearchEmail && searchBy === 'email')}
                  onClick={() => {
                    setisOpenSearchEmail(!isOpenSearchEmail);
                    setSearchBy('email');
                  }}
                >
                  <Tooltip content={t('activity.searchBtn')}>
                    {(tooltipProps) => (
                      <SearchIcon
                        {...tooltipProps}
                        size='small'
                        primaryColor='#6D6E6F'
                      />
                    )}
                  </Tooltip>
                </div>
              )}
            />
          </div>
        ),
        width: 20,
      },
      {
        key: 'status',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.statusHeading')}
          </div>
        ),
        isSortable: false,
        width: 10,
      },
      {
        key: 'mfa_required',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.mfaRequired')}
          </div>
        ),
        isSortable: false,
        width: 10,
      },
      {
        key: 'mfa_status',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.mfaStatus')}
          </div>
        ),
        isSortable: false,
        width: 15,
      },
      {
        key: 'last_login_date',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.lastLoginDate')}
          </div>
        ),
        isSortable: false,
        width: 15,
      },
      {
        key: 'action',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.actionHeading')}
          </div>
        ),
        isSortable: false,
        width: 15,
      },
    ],
  };

  const handleFinalData = (data) => {
    const isEmailCompare = (valueA, valueB) =>
      selectedSort.sortOrder === 'ASC'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    if (!data || data.length === 0) {
      return [];
    }
    return [...data].sort((a, b) =>
      isEmailCompare(a?.[selectedSort.key], b?.[selectedSort.key]),
    );
  };

  const rows = handleFinalData(userData).map((user) => ({
    key: user.accountId,
    cells: [
      {
        key: user.siteId,
        content: <div>{user.siteName}</div>,
      },
      {
        key: user.email,
        content: <div>{user?.email}</div>,
      },
      {
        key: 'status',
        content: (
          <Status
            text={
              user.status === 'CONFIRMED'
                ? t('userManagement.status.active')
                : t('userManagement.status.notLoginYet')
            }
            color={user.status === 'CONFIRMED' ? 'green' : 'yellow'}
          />
        ),
        width: 10,
      },
      {
        key: 'mfa_required',
        content: (
          <MfaToggle
            checked={user?.mfaRequired}
            isDisabled={user.status !== 'CONFIRMED'}
            loadingUsers={loadingUsers}
            onClickToggleMFARequire={async (newValue) => {
              if (newValue) {
                // enable MFA required
                newRelicUtils.addPageAction(
                  PAGE_ACTION.userManagement.turnOnMFARequire,
                  {
                    siteIds: getSiteIds(sites),
                    targetIds: user?.userId,
                  },
                );
                const body = {
                  accountId: user?.userId,
                  siteId: getSiteIds(sites),
                };
                await mfaAPIs.changeMfaRequiredSite(body);
              } else {
                // disable MFA required
                setActionConfirmAccount(user);
                setNotificationConfirm({
                  type: ACTION_USER_TYPE_CONFIRM.TURN_OFF_MFA,
                  name: user.name,
                  title: t('userManagement.dialog.turnOffMFA.title'),
                  message: t('userManagement.dialog.turnOffMFA.message'),
                  submitText: t('common.save'),
                });
              }
            }}
          />
        ),
      },
      {
        key: 'mfa_status',
        content: (
          <>
            {user?.mfaStatus ? (
              <Status
                text={t('userManagement.status.registered')}
                color='green'
              />
            ) : (
              <Status
                text={t('userManagement.status.unregistered')}
                color='neutral'
              />
            )}
          </>
        ),
      },
      {
        key: 'last_login_date',
        content: (
          <div>
            {user.lastLoginDate ? renderLastLoginDate(user.lastLoginDate) : ''}
          </div>
        ),
      },
      {
        key: 'action',
        content: (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <Button
              style={{ paddingRight: '10px', paddingLeft: '0px' }}
              isDisabled={user.userId === currentUser.id}
              appearance='link'
              onClick={() => {
                setActionConfirmAccount(user);
                setNotificationConfirm({
                  type: ACTION_USER_TYPE_CONFIRM.REMOVE_USER,
                  name: user.name || user.email,
                  title: t('userManagement.dialog.removeUser.title'),
                  message: t('userManagement.dialog.removeUser.message'),
                  submitText: t('common.remove'),
                });
              }}
            >
              {t('common.remove')}
            </Button>
            {user.status !== 'CONFIRMED' &&
              (reinvitingAccountId === user?.userId ? (
                <div className={classes.wrapSpinner}>
                  <Spinner size='medium' />
                </div>
              ) : (
                <Button
                  style={{ paddingRight: '10px', paddingLeft: '0px' }}
                  isDisabled={user.userId === currentUser.id}
                  appearance='link'
                  onClick={() => {
                    reInviteUser(user);
                  }}
                >
                  {t('common.reinvite')}
                </Button>
              ))}
            {user?.mfaStatus && (
              <Button
                style={{ paddingRight: '10px', paddingLeft: '0px' }}
                appearance='link'
                onClick={() => {
                  setActionConfirmAccount(user);
                  setNotificationConfirm({
                    type: ACTION_USER_TYPE_CONFIRM.RESET_DEVICE_MFA,
                    name: user.name || user.email,
                    title: t('userManagement.dialog.resetMFA.title'),
                    message: t('userManagement.dialog.resetMFA.message'),
                    submitText: t('userManagement.resetMfa'),
                  });
                }}
              >
                {t('userManagement.resetMfa')}
              </Button>
            )}
          </div>
        ),
      },
    ],
  }));

  const totalPages = useMemo(
    () =>
      Math.ceil(
        userData?.length / (rowsPerPage?.value || defaultRowsPerPage),
      ) || 1,
    [JSON.stringify(siteUsers), JSON.stringify(userData), rowsPerPage],
  );

  useEffect(() => {
    if (siteSearching?.length) {
      handleSearchFilter(siteSearching);
    }
  }, [siteSearching]);

  useEffect(() => {
    if (sites) {
      if (!checkAllSitesDisable(sites)) loadUsersCallback(siteIdsSearching);
      else setLoadingUsers(false);
    }
  }, [sites]);

  useEffect(() => {
    if (!loadingMenu && !sites) {
      setLoadingUsers(false);
    }
  }, [loadingMenu, optionSites.length]);

  useEffect(() => {
    setUserData(siteUsers);
    setCurrentPage(1);
    handleReset();
    setSearchTempValue('');
    setSearchBy('');
  }, [JSON.stringify(siteUsers)]);

  useEffect(() => {
    setRowsPerPage({
      label: `${defaultRowsPerPage} / ${t('userManagement.page')}`,
      value: defaultRowsPerPage,
    });
  }, [defaultRowsPerPage]);

  useEffect(() => {
    handleSearch();
  }, [searchKeyEmail, searchKeyName, JSON.stringify(siteUsers)]);

  return (
    <UserManagerContext.Provider
      value={{
        siteUsers,
        siteIdsSearching,
        loadUsers: loadUsersCallback,
      }}
    >
      <div className={classes.header}>
        <TopFilter
          siteSearching={siteSearching}
          onChangeFilter={({ siteSearching: selectedSites }) => {
            newRelicUtils.addPageAction(PAGE_ACTION.userActivity.filter, {
              siteIds: getSiteIds(selectedSites),
            });
            setSiteSearching(selectedSites);
          }}
          onClickInviteBtn={onClickInviteBtn}
        />
      </div>

      <CollapseSection
        hideExpandedIcon
        defaultOpen
        id='activityUser'
        renderTitle={() => (
          <div className={classes.headerSection}>
            <h4
              style={{
                fontSize: '24px',
                margin: 0,
              }}
            >
              {t('navBar.userManagePage')}
            </h4>
            <div className={classes.pagTable}>
              <Select
                blurInputOnSelect
                className={classes.rowsPerPage}
                value={rowsPerPage}
                inputValue=''
                isSearchable={false}
                options={[
                  {
                    label: `${defaultRowsPerPage} / ${t(
                      'userManagement.page',
                    )}`,
                    value: defaultRowsPerPage,
                  },
                  {
                    label: `20 / ${t('userManagement.page')}`,
                    value: 20,
                  },
                  {
                    label: `50 / ${t('userManagement.page')}`,
                    value: 50,
                  },
                  {
                    label: `100 / ${t('userManagement.page')}`,
                    value: 100,
                  },
                ]}
                onChange={(newValue) => {
                  setRowsPerPage(newValue);
                  setCurrentPage(1);
                }}
              />
              {userData?.length > 0 ? (
                <PaginationTotalNumber
                  currentPage={currentPage}
                  actualItem={userData?.length || 0}
                  rowsPerPage={rowsPerPage.value}
                  totalPage={userData?.length || 0}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      >
        <div className={classes.main}>
          <div className={classes.container}>
            <div className={classes.tableResponsive}>
              <DynamicTable
                isLoading={loadingUsers}
                loadingSpinnerSize='large'
                head={head}
                emptyView={emptyTable()}
                rows={rows}
                rowsPerPage={rowsPerPage?.value}
                defaultPage={1}
                page={currentPage}
                isFixedSize='true'
              />
            </div>
            {userData?.length !== 0 && (
              <div className={classes.pagination}>
                <Pagination
                  defaultSelectedIndex={currentPage - 1}
                  selectedIndex={currentPage - 1}
                  pages={Array.from(
                    {
                      length: totalPages,
                    },
                    (_v, i) => i + 1,
                  )}
                  onChange={(data, page) => setCurrentPage(page)}
                />
              </div>
            )}
          </div>
        </div>
        {isInviteDialogOpen && (
          <InviteUserDialog
            optionSites={optionSites}
            sitesDefault={sites.map((site) => ({
              label: site.name,
              value: site.value,
            }))}
            onClose={() => setIsInviteDialogOpen(false)}
          />
        )}
        {actionConfirmAccount && (
          <ActionUserConfirmModal
            account={actionConfirmAccount}
            onClose={() => setActionConfirmAccount(null)}
            notification={notificationConfirm}
          />
        )}
      </CollapseSection>
    </UserManagerContext.Provider>
  );
};

export default UsersBoard;
